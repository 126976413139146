import { Config } from './config.interface';

export default {
  menus: [
    'INICIO',
    'CONFIGURACOES',
    'SESSAO_CADASTROS',
    'LISTA_BENEFICIÁRIOS',
    'CANCELAMENTO_BENEFICIÁRIO',

    'SESSAO_EXTRATO',

    'EXTRATO_COPART',
    'EXTRATO_COBRANCA',

    'SESSAO_PGTOS_E_DEBITOS',
    'DECL_ANUAL_DEB',
    'CARTA_QUITACAO',
    'INFORME_PGTOS',

    'SESSAO_SEGUNDA_VIA',
    'SEGUNDA_VIA_BOLETO',

    'SESSAO_DOWNLOADS',
    'GUIA_DO_CONSUMIDOR',
  ],
  labels: {
    MENU_INICIO: 'Início',
    MENU_SESSAO_CADASTROS: 'Movimentação',
    MENU_LISTA_BENEFICIÁRIOS: 'Visualizar Beneficiários',
    MENU_IMPORTACAO_BENEFICIÁRIOS: 'Importar Beneficiários',
    MENU_CANCELAMENTO_BENEFICIÁRIO: 'Cancelamento RN561',
    MENU_SESSAO_EXTRATO: 'Extrato',
    MENU_EXTRATO_COPART: 'Extrato de coparticipação',
    MENU_EXTRATO_COBRANCA: 'Extrato de cobrança',
    MENU_SESSAO_PGTOS_E_DEBITOS: 'Pagamentos e débitos',
    MENU_DECL_ANUAL_DEB: 'Declaração anual de débito',
    MENU_CARTA_QUITACAO: 'Carta de quitação',
    MENU_INFORME_PGTOS: 'Informe de pagamentos',
    MENU_SESSAO_SEGUNDA_VIA: '2º Via',
    MENU_SEGUNDA_VIA_BOLETO: 'Segunda via de boleto',
    MENU_SESSAO_DOWNLOADS: 'Downloads',
    MENU_GUIA_DO_CONSUMIDOR: 'Guia do consumidor',
    MENU_SESSAO_FAQ: 'Perguntas Frequentes',

    HEADER_SITE_INSTITUCIONAL: 'Site Institucional',
    DADOS_CADASTRAIS: 'Dados de cobrança',

    FILA_INTEGRACAO: 'Fila de Integração',
  },
  configurations: {
    PORTAL_PF_TITLE: 'Portal do Cliente',
    PORTAL_PJ_TITLE: 'Portal do RH',
    AUTH_SHOULD_SHOW_SUBTITLE: true,
    AUTH_SHOULD_SHOW_BUTTONS: true,
    FIRST_ACCESS_CODE_FIELD: 'WEB_VALIDATION_CODE',
    CAN_EDIT_BENEFICIARY: true,
    SHOW_TYPE_BENEFIT_FILTER: false,
    HOME_BANNER: false,
    COPART_COLUMNS: ['amount', 'billingDate', 'procedure', 'provider', 'quantity', 'serviceDate'],
    ENABLE_EMAIL_CREATE_ACCOUNT: false,
    EMAIL_NON_CONFIRMED_TEXT: 'O e-mail de acesso não foi confirmado! Para reenviar o e-mail de confirmação',
    SHOW_RESEND_EMAIL_LINK: true,
    CAN_ADD_DEPENDENT: true,
    CAN_EDIT_BENEFICIARY_CELLPHONE: true,
    CAN_EDIT_BENEFICIARY_EMAIL: true,
    BENEFICIARY_SHOW_CONTRACT: true,
    BENEFICIARY_SHOW_SUB_CONTRACT: false,
    SHOULD_SEND_IBGE_CODE_ON_GET_PLANS: false,
    SHOULD_SHOW_RULE_FIELD_ON_BENEFICIARY_FORM: false,
    DATA_DE_VIGENCIA_EDITAVEL_PARA_RECEM_CASADO_RECEM_NASCIDO: false,
    SEGURANCA_INFORMACAO_ROUTE: '/politicas/politica-de-seguranca-da-informacao',
    PRIVACIDADE_ROUTE: '/politicas/politica-de-privacidade',
    SHOUD_PHONE_FOOTER_BE_WHITE: false,
    SHOUD_BANNER_CAROUSEL_DYNAMIC_ROTATE: false,
    SHOUD_ADD_BANNERS_TYPES: false,
    PJ: {
      configurations: {
        CAN_BLOCK_BENEFICIARY: true,
        CAN_EDIT_BENEFICIARY_PLAN: true,
        CAN_ADD_BENEFICIARY: true,
      },
    },
    FORM_BENEFICIARIO_NOT_REQUIRED: {
      fields: [],
    },
  },
} as Config;
