<form [formGroup]="formGroup" (ngSubmit)="onSubmit()">
  <div class="row">
    <div class="col-md-12 col-lg-5">
      <mat-form-field appearance="outline" class="w-100">
        <mat-label>Nome Completo</mat-label>
        <input type="text" matInput formControlName="name" />
        <mat-error *ngIf="formGroup.get('name')?.hasError('required')">Este campo é obrigatório</mat-error>
      </mat-form-field>
    </div>

    <div class="col-lg-3">
      <mat-form-field appearance="outline" class="w-100">
        <mat-label>Data de Nascimento</mat-label>
        <input type="text" matInput formControlName="birthDate" mask="00/00/0000" placeholder="dd/mm/yyyy" />
        <mat-error *ngIf="formGroup.get('birthDate')?.hasError('required')">Este campo é obrigatório</mat-error>
        <mat-error *ngIf="formGroup.get('birthDate')?.hasError('dateBeforeToday')">
          Insira uma data anterior ou igual a hoje
        </mat-error>
      </mat-form-field>
    </div>

    <div class="col-md-12 col-lg-4">
      <mat-form-field appearance="outline" class="w-100">
        <mat-label>Nome Social</mat-label>
        <input type="text" matInput formControlName="preferredName" />
      </mat-form-field>
    </div>

    <div class="col-md-6 col-lg-3" *ngIf="mode === 'Adicionar' && type === 'Dependente'">
      <mat-form-field appearance="outline" class="w-100">
        <mat-label>Grau de parentesco</mat-label>

        <mat-select formControlName="kinship">
          <mat-option *ngIf="!formGroup.controls.birthDate.value; else empty" disabled>
            Insira a Data de Nascimento
          </mat-option>
          <ng-template #empty>
            <mat-option disabled *ngIf="!kinships?.length">Nenhum grau de parentesco encontrado</mat-option>
          </ng-template>
          <mat-option *ngFor="let kinship of kinships" [value]="kinship.descricao">
            {{ kinship.descricao }}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="formGroup.get('kinship')?.hasError('required')">Este campo é obrigatório</mat-error>
      </mat-form-field>
    </div>

    <div class="col-md-6 col-lg-3" [hidden]="selectedKinship !== 'ESPOSO(A)'">
      <mat-form-field appearance="outline" class="w-100">
        <mat-label>Data de Casamento</mat-label>
        <input type="text" matInput formControlName="weddingDate" mask="00/00/0000" />
        <mat-error *ngIf="formGroup.get('weddingDate')?.hasError('mask')">Preencha este campo corretamente</mat-error>
        <mat-error *ngIf="formGroup.get('weddingDate')?.hasError('dateBeforeToday')">
          Insira uma data anterior ou igual a hoje
        </mat-error>
      </mat-form-field>
    </div>

    <ng-container *ngIf="mode === 'Adicionar' && type === 'Titular' && this.showContract">
      <div class="col-md-6 col-lg-3">
        <mat-form-field appearance="outline" class="w-100">
          <mat-label>Contrato</mat-label>
          <mat-select formControlName="contract">
            <mat-option *ngFor="let contract of contracts" [value]="contract">
              {{ contract.codeContract }}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="formGroup.get('contract')?.hasError('required')">Este campo é obrigatório</mat-error>
        </mat-form-field>
      </div>
    </ng-container>

    <ng-container *ngIf="mode === 'Adicionar' && type === 'Titular' && this.showSubContract">
      <div class="col-md-6 col-lg-3">
        <mat-form-field appearance="outline" class="w-100">
          <mat-label>Apólice</mat-label>
          <mat-select formControlName="contract">
            <mat-option *ngFor="let contract of policys" [value]="contract.codeContract">
              {{ contract.policy }}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="formGroup.get('contract')?.hasError('required')">Este campo é obrigatório</mat-error>
        </mat-form-field>
      </div>
    </ng-container>

    <ng-container *ngIf="mode === 'Adicionar' && type === 'Titular' && this.showSubContract">
      <div class="col-md-6 col-lg-3">
        <mat-form-field appearance="outline" class="w-100">
          <mat-label>Subcontrato</mat-label>
          <mat-select formControlName="subContract">
            <mat-option
              *ngFor="let contract of getSubContractsForContract(formGroup.get('contract')?.value)"
              [value]="contract.subContract"
            >
              {{ contract.codeoper }}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="formGroup.get('subContract')?.hasError('required')">Este campo é obrigatório</mat-error>
        </mat-form-field>
      </div>
    </ng-container>

    <div class="col-md-6 col-lg-3">
      <mat-form-field appearance="outline" class="w-100">
        <mat-label>Data de Vigência</mat-label>
        <input type="text" matInput formControlName="effectiveDate" mask="00/00/0000" />
        <mat-error *ngIf="formGroup.get('effectiveDate')?.hasError('required')">Este campo é obrigatório</mat-error>
        <mat-error *ngIf="formGroup.get('effectiveDate')?.hasError('invalidDate')">Insira uma data válida</mat-error>
        <mat-error *ngIf="formGroup.get('effectiveDate')?.hasError('notPermittedDate')">
          {{ formGroup.get('effectiveDate')?.errors?.notPermittedDate }}
        </mat-error>
      </mat-form-field>
    </div>

    <div
      class="col-md-6 col-lg-3"
      *ngIf="config.getConfiguration('SHOULD_SHOW_RULE_FIELD_ON_BENEFICIARY_FORM') && type === 'Titular'"
    >
      <mat-form-field appearance="outline" class="w-100">
        <mat-label>Cargo</mat-label>
        <mat-select formControlName="ruleCode">
          <mat-option *ngFor="let rule of rules" [value]="rule.codigo">{{ rule.descricao }}</mat-option>
        </mat-select>
        <mat-error *ngIf="formGroup.get('ruleCode')?.hasError('required')">Este campo é obrigatório</mat-error>
      </mat-form-field>
    </div>

    <div class="col-md-6 col-lg-3">
      <mat-form-field appearance="outline" class="w-100">
        <mat-label>CPF</mat-label>
        <input type="text" matInput formControlName="cpf" mask="000.000.000-00" />
        <mat-error *ngIf="formGroup.get('cpf')?.hasError('required')">Este campo é obrigatório</mat-error>
        <mat-error *ngIf="formGroup.get('cpf')?.hasError('cpfNotValid')">Digite um CPF válido</mat-error>
      </mat-form-field>
    </div>

    <div class="col-md-6 col-lg-3">
      <mat-form-field appearance="outline" class="w-100">
        <mat-label>RG</mat-label>
        <input type="text" matInput formControlName="rg" />
        <mat-error *ngIf="formGroup.get('rg')?.hasError('required')">Este campo é obrigatório</mat-error>
      </mat-form-field>
    </div>

    <div class="col-md-6 col-lg-3" *ngIf="mode === 'Adicionar'">
      <mat-form-field appearance="outline" class="w-100">
        <mat-label>Orgão emissor</mat-label>
        <input type="text" matInput formControlName="issuer" />
        <mat-error *ngIf="formGroup.get('issuer')?.hasError('required')">Este campo é obrigatório</mat-error>
      </mat-form-field>
    </div>

    <div class="col-md-6 col-lg-4">
      <mat-form-field appearance="outline" class="w-100">
        <mat-label>Lotacao </mat-label>
        <input type="text" matInput formControlName="lotacao" />
        <mat-hint>*Inclusão referente a operadora CNU (Unimed) o campo é obrigatório</mat-hint>
      </mat-form-field>
    </div>

    <div class="col-md-6 col-lg-4">
      <mat-form-field appearance="outline" class="w-100">
        <mat-label>Sexo</mat-label>
        <mat-select formControlName="gender">
          <mat-option value="Masculino">Masculino</mat-option>
          <mat-option value="Feminino">Feminino</mat-option>
        </mat-select>
        <mat-error *ngIf="formGroup.get('gender')?.hasError('required')">Este campo é obrigatório</mat-error>
      </mat-form-field>
    </div>

    <div class="col-md-6 col-lg-4">
      <mat-form-field appearance="outline" class="w-100">
        <mat-label>Estado Civil</mat-label>
        <mat-select formControlName="maritalStatus">
          <mat-option value="CASADO(A)">Casado(a)</mat-option>
          <mat-option value="Divorciado(a)">Divorciado(a)</mat-option>
          <mat-option value="Uniao Estavel">Uniao Estavel</mat-option>
          <mat-option value="Separado">Separado</mat-option>
          <mat-option value="Desquitado(a)">Desquitado(a)</mat-option>
          <mat-option value="Solteiro(a)">Solteiro(a)</mat-option>
          <mat-option value="Viuvo(a)">Viuvo(a)</mat-option>
        </mat-select>
        <mat-error *ngIf="formGroup.get('maritalStatus')?.hasError('required')">Este campo é obrigatório</mat-error>
      </mat-form-field>
    </div>

    <div class="col-md-12 col-lg-6">
      <mat-form-field appearance="outline" class="w-100">
        <mat-label>Nome da Mãe</mat-label>
        <input type="text" matInput formControlName="motherName" />
        <mat-error *ngIf="formGroup.get('motherName')?.hasError('required')">Este campo é obrigatório</mat-error>
      </mat-form-field>
    </div>

    <div class="col-md-6 col-lg-3" *ngIf="mode === 'Editar' && type === 'Titular'">
      <mat-form-field appearance="outline" class="w-100">
        <mat-label>Número da carteirinha</mat-label>
        <input type="text" matInput formControlName="cardNumber" />
        <mat-error *ngIf="formGroup.get('cardNumber')?.hasError('required')">Este campo é obrigatório</mat-error>
      </mat-form-field>
    </div>

    <div class="col-md-6 col-lg-3" *ngIf="mode === 'Editar' && type === 'Titular'">
      <mat-form-field appearance="outline" class="w-100">
        <mat-label>Número da carteirinha odonto</mat-label>
        <input type="text" matInput formControlName="cardNumberOdonto" />
        <mat-error *ngIf="formGroup.get('cardNumberOdonto')?.hasError('required')">Este campo é obrigatório</mat-error>
      </mat-form-field>
    </div>

    <div class="col-md-12 col-lg-6">
      <mat-form-field appearance="outline" class="w-100">
        <mat-label>E-mail</mat-label>
        <input type="email" matInput formControlName="email" />
        <mat-error *ngIf="formGroup.get('email')?.hasError('required')">Este campo é obrigatório</mat-error>
      </mat-form-field>
    </div>

    <div class="col-md-6 col-lg-4">
      <mat-form-field appearance="outline" class="w-100">
        <mat-label>Telefone Fixo</mat-label>
        <input type="text" matInput formControlName="homePhone" mask="(00) 0000-0000" />
        <mat-error *ngIf="formGroup.get('homePhone')?.hasError('required')">Este campo é obrigatório</mat-error>
      </mat-form-field>
    </div>

    <div class="col-md-6 col-lg-4">
      <mat-form-field appearance="outline" class="w-100">
        <mat-label>Telefone Celular</mat-label>
        <input type="text" matInput formControlName="cellNumber" mask="(00) 00000-0000" />
        <mat-error *ngIf="formGroup.get('cellNumber')?.hasError('required')">Este campo é obrigatório</mat-error>
      </mat-form-field>
    </div>

    <div class="col-md-6 col-lg-4">
      <mat-form-field appearance="outline" class="w-100">
        <mat-label>CEP</mat-label>
        <input type="text" matInput formControlName="cep" (blur)="loadCep()" mask="00000-000" />
        <mat-error *ngIf="formGroup.get('cep')?.hasError('required')">Este campo é obrigatório</mat-error>
      </mat-form-field>
    </div>

    <div class="col-md-6 col-lg-6">
      <mat-form-field appearance="outline" class="w-100">
        <mat-label>Logradouro</mat-label>
        <input type="text" matInput formControlName="address" />
        <mat-error *ngIf="formGroup.get('address')?.hasError('required')">Este campo é obrigatório</mat-error>
      </mat-form-field>
    </div>

    <div class="col-md-6 col-lg-3">
      <mat-form-field appearance="outline" class="w-100">
        <mat-label>Número</mat-label>
        <input type="text" matInput formControlName="addressNumber" />
        <mat-error *ngIf="formGroup.get('addressNumber')?.hasError('required')">Este campo é obrigatório</mat-error>
        <mat-error *ngIf="formGroup.get('addressNumber')?.hasError('maxlength')">Tamanho máximo 6 caracteres</mat-error>
      </mat-form-field>
    </div>

    <div class="col-md-6 col-lg-3">
      <mat-form-field appearance="outline" class="w-100">
        <mat-label>Complemento</mat-label>
        <input type="text" matInput formControlName="addressComplement" />
        <mat-error *ngIf="formGroup.get('addressComplement')?.hasError('required')">Este campo é obrigatório</mat-error>
      </mat-form-field>
    </div>

    <div class="col-md-6 col-lg-4">
      <mat-form-field appearance="outline" class="w-100">
        <mat-label>Bairro</mat-label>
        <input type="text" matInput formControlName="district" />
        <mat-error *ngIf="formGroup.get('district')?.hasError('required')">Este campo é obrigatório</mat-error>
      </mat-form-field>
    </div>

    <div class="col-md-6 col-lg-4">
      <mat-form-field appearance="outline" class="w-100">
        <mat-label>Cidade</mat-label>
        <input type="text" matInput formControlName="city" />
        <mat-error *ngIf="formGroup.get('city')?.hasError('required')">Este campo é obrigatório</mat-error>
      </mat-form-field>
    </div>

    <div class="col-md-6 col-lg-4">
      <mat-form-field appearance="outline" class="w-100">
        <mat-label>Estado</mat-label>
        <input type="text" matInput formControlName="uf" />
        <mat-error *ngIf="formGroup.get('uf')?.hasError('required')">Este campo é obrigatório</mat-error>
      </mat-form-field>
    </div>
  </div>

  <div class="mt-3">
    <a mat-stroked-button color="secondary" type="button" (click)="canceled.emit()">Voltar</a>
    <button mat-raised-button color="accent" type="submit" class="ms-2">
      Continuar
    </button>
  </div>
</form>
