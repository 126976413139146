<app-breadcrumb
  [steps]="[
    ['Início', 'inicio'],
    ['Selecionar Beneficiario', 'dados-cadastrais/selecionar-beneficiario'],
    [separarCamelCase(mode) + ' ' + type, '']
  ]"
></app-breadcrumb>

<mat-card class="mt-4">
  <h4 class="mb-4">{{ separarCamelCase(mode) }} {{ type }} {{ beneficiary?.name }}</h4>
  <mat-stepper orientation="vertical" linear *ngIf="showStepper">
    <mat-step *ngIf="mode !== 'EditarPlano'" [stepControl]="beneficiaryFormGroup">
      <ng-template matStepLabel>Dados do Beneficiário</ng-template>
      <ng-template matStepContent>
        <app-beneficiary-form
          [type]="type"
          [mode]="mode"
          [beneficiaryId]="beneficiaryId"
          [beneficiary]="beneficiary"
          [beneficiaryParentId]="beneficiaryParentId"
          (saved)="onFormSaved($event)"
          (canceled)="onFormCanceled()"
        ></app-beneficiary-form>
      </ng-template>
    </mat-step>
    <mat-step *ngIf="mode === 'Adicionar' || mode === 'EditarPlano'" [stepControl]="planFormGroup">
      <ng-template matStepLabel>Plano</ng-template>
      <ng-template matStepContent>
        <app-beneficiary-plans
          [type]="type"
          [mode]="mode"
          [beneficiaryId]="beneficiaryParentId"
          [beneficiaryParent]="beneficiaryParent"
          [beneficiaryFormValue]="beneficiaryFormValue"
          (canceled)="goToStep(0)"
          (finished)="onPlanSelected($event)"
        ></app-beneficiary-plans>
      </ng-template>
    </mat-step>
    <mat-step *ngIf="mode === 'Adicionar' && shouldShowDsSection" [stepControl]="dpsFormGroup">
      <ng-template matStepLabel>Declaração de saúde</ng-template>
      <ng-template matStepContent>
        <app-beneficiary-health-statement
          (canceled)="goToStep(1)"
          (saved)="onSaveDps($event)"
        ></app-beneficiary-health-statement>
      </ng-template>
    </mat-step>
    <mat-step *ngIf="mode === 'Adicionar' || mode === 'Editar'" [stepControl]="documentsFormGroup">
      <ng-template matStepLabel>Documentos</ng-template>
      <ng-template matStepContent>
        <app-beneficiary-documents
          (saved)="onDocumentsSaved($event)"
          (canceled)="goToStep(2)"
          [mode]="mode"
          [beneficiaryType]="type"
          [beneficiary]="beneficiaryFormValue"
          [beneficiaryParentId]="beneficiaryParentId"
        ></app-beneficiary-documents>
      </ng-template>
    </mat-step>
  </mat-stepper>
</mat-card>
