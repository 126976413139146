import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';

import { faFileAlt, faFileUpload } from '@fortawesome/free-solid-svg-icons';
import { LoaderService } from 'src/app/core/services/loader';
import { NotificationService } from 'src/app/core/services/notification';
import { BeneficiaryDocument } from '../../models/BeneficiaryDocument';
import { DocumentResult } from '../../models/DocumentResult';
import { DocumentService } from '../../services/document';
import { BeneficiaryType } from '../../types/BeneficiaryType';
import { Beneficiary } from 'src/app/core/models/Beneficiary';

@Component({
  selector: 'app-beneficiary-documents',
  templateUrl: './beneficiary-documents.component.html',
  styleUrls: ['./beneficiary-documents.component.scss'],
})
export class BeneficiaryDocumentsComponent implements OnInit {
  @Input() beneficiaryType!: BeneficiaryType;
  @Input() beneficiary: Beneficiary | null = null;
  @Input() beneficiaryParentId: string | null = null;
  @Input() beneficiaryParent: Beneficiary | null = null;
  @Input() mode!: 'Adicionar' | 'Editar' | 'EditarPlano' | 'Bloquear' | 'Desbloquear';

  @Output() saved = new EventEmitter<DocumentResult[]>();

  @Output() canceled = new EventEmitter();

  faFileAlt = faFileAlt;
  faFileUpload = faFileUpload;

  documents!: BeneficiaryDocument[];

  formGroup!: FormGroup;

  sent = false;

  constructor(
    private documentService: DocumentService,
    private loader: LoaderService,
    private formBuilder: FormBuilder,
    private notification: NotificationService
  ) {}

  get documentsFormArray(): FormArray {
    return this.formGroup?.get('documents') as FormArray;
  }

  ngOnInit(): void {
    this.loadDocuments();
  }

  onInputChanged(event: File, index: number): void {
    this.documentsFormArray.at(index).setValue(event);
  }

  hasValue(index: number): boolean {
    return !!this.documentsFormArray.at(index).value;
  }

  onRemoved(index: number): void {
    this.documentsFormArray.at(index).reset();
  }

  onBackClick(): void {
    this.canceled.emit();
  }

  onSubmit(): void {
    this.sent = true;

    if (this.formGroup.invalid) {
      this.notification.info('Por favor, insira os documentos necessários.');
      return;
    }

    this.emitSaved();
  }

  private loadDocuments(): void {
    this.loader.show('Buscando documentos...');

    if (this.beneficiary === null) {
      this.loader.hide();
      return;
    }

    let plan = this.beneficiary?.healthPlan === undefined || this.beneficiary?.healthPlan === null ? null : this.beneficiary.healthPlan;
    if (this.beneficiary.type[0] === 'D') {
      plan = this.beneficiaryParent === undefined || this.beneficiaryParent === null || this.beneficiaryParent?.healthPlan === null ? null : this.beneficiaryParent.healthPlan;
    }

    const contract = plan === null || plan.contract === undefined ? "undefined" : plan.contract;
    const subContract = plan === null || plan.subContract === undefined ? "undefined" : plan.subContract;

    this.documentService
    .getDocumentsV02(this.mode, this.beneficiary.type[0], this.beneficiary.kinship, contract, subContract)
      .subscribe((documents) => {
        this.documents = documents;
        this.buildForm();
      })
      .add(() => {
        this.loader.hide();
      });
  }

  private buildForm(): void {
    this.formGroup = this.formBuilder.group({
      documents: this.formBuilder.array(
        this.documents.map((d) => this.formBuilder.control(null, d.obrigatorio ? Validators.required : undefined))
      ),
    });
  }

  private emitSaved(): void {
    this.saved.emit(
      this.documents
        .map((d, i) => ({
          codigo: d.codigo,
          descricao: d.descricao,
          obrigatorio: d.obrigatorio,
          file: this.documentsFormArray.at(i).value,
        }))
        .filter((d) => !!d.file)
    );
  }
}
