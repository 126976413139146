import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, FormBuilder } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { faEye, faIdCard, faLock, faUserEdit, faUserPlus } from '@fortawesome/free-solid-svg-icons';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { Observable, merge } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Beneficiary } from 'src/app/core/models/Beneficiary';
import { BenefitType } from 'src/app/core/models/BenefitType';
import { BeneficiaryService } from 'src/app/core/services/beneficiary';
import { ConfigService } from 'src/app/core/services/config';
import { LoaderService } from 'src/app/core/services/loader';
import { IAppState } from 'src/app/core/store/interfaces/IAppState';
import { selectTenantNome, selectUserType } from 'src/app/core/store/selectors';
import { SimpleBeneficiary } from '../../../../core/models/SimpleBeneficiary';
import * as XLSX from 'xlsx'; // Biblioteca para criar arquivos XLSX
import { saveAs } from 'file-saver'; // Biblioteca para salvar o arquivo no navegador
import { tap } from 'rxjs/operators';

@Component({
  selector: 'app-tabela-alteracao-cadastral',
  templateUrl: './tabela-alteracao-cadastral.component.html',
  styleUrls: ['./tabela-alteracao-cadastral.component.scss'],
})
export class TabelaAlteracaoCadastralComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild(MatPaginator)
  paginator!: MatPaginator;

  dataSource = new MatTableDataSource<SimpleBeneficiary>();

  displayedColumns = [
    'name',
    'birthDate',
    'kinship',
    'cardNumber',
    'contract',
    'subcontract',
    'segmento',
    'operadora',
    'actions',
  ];

  userType$ = this.store.select(selectUserType);

  tenantNome$ = this.store.select(selectTenantNome);

  faUserPlus = faUserPlus;
  faUserEdit = faUserEdit;
  faEye = faEye;
  faLock = faLock;
  faIdCard = faIdCard;
  benefitTypeControl = new FormControl<BenefitType | null>(null);

  destroyed$ = new Subject<void>();

  benefitTypes?: BenefitType[];

  pesquisaCtrl = new FormControl('');
  operadoraCtrl = new FormControl('');
  segmentoCtrl = new FormControl('');
  contratoCtrl = new FormControl('');
  subcontratoCtrl = new FormControl('');

  constructor(
    private beneficiaryService: BeneficiaryService,
    private router: Router,
    private loaderService: LoaderService,
    private store: Store<IAppState>,
    private activatedRoute: ActivatedRoute,
    public config: ConfigService
  ) {}

  ngOnInit(): void {
    this.loadBenefitTypes();
    this.registerSearch();
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator;
  }

  shouldShowAddDependentButton(beneficiary: Beneficiary): boolean {
    return beneficiary.kinship === 'Titular';
  }

  getEditBeneficiaryRoute(beneficiary: Beneficiary): string {
    let url = '/dados-cadastrais';
    const isTitular = beneficiary.kinship === 'Titular';
    url += `/alterar-${isTitular ? 'titular' : 'dependente'}`;

    url += `/${beneficiary.idBeneficiary}`;

    return url;
  }

  getEditPlanRoute(beneficiary: Beneficiary): string {
    let url = '/dados-cadastrais';
    const isTitular = beneficiary.kinship === 'Titular';
    url += `/alterar-plano-${isTitular ? 'titular' : 'dependente'}`;

    url += `/${beneficiary.idBeneficiary}`;

    return url;
  }

  exportToExcel(): void {
    // Obter os dados da tabela
    const data = this.dataSource.filteredData.map((beneficiary) => ({
      Nome: beneficiary.name,
      'Data de Nascimento': beneficiary.birthDate,
      Parentesco: beneficiary.kinship,
      Carteirinha: beneficiary.cardNumber,
      Contrato: beneficiary.contract,
      Subcontrato: beneficiary.subcontract,
      Segmento: beneficiary.segmento,
      Operadora: beneficiary.operadora
    }));
  
    // Adicionar os cabeçalhos das colunas
    const headers = [
      'Nome',
      'Data de Nascimento',
      'Parentesco',
      'Carteirinha',
      'Contrato',
      'Subcontrato',
      'Segmento',
      'Operadora'
    ];
  
    // Criar uma nova planilha
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(data, { header: headers });
  
    // Criar um novo workbook e adicionar a planilha
    const workbook: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Beneficiários');
  
    // Gerar o arquivo Excel e salvar
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    const blob: Blob = new Blob([excelBuffer], { type: 'application/octet-stream' });
    saveAs(blob, 'Beneficiarios.xlsx');
  }

  private loadBeneficiaries(): void {
    this.dataSource.data = [];
    const benefitType = this.benefitTypeControl.value;

    this.loaderService.show('Carregando beneficiários...');
    this.beneficiaryService
      .getBeneficiaries({
        benefitType: benefitType?.type,
        idClient: benefitType?.idClient,
        flat: benefitType?.flat,
        contract: benefitType?.contract,
        subcontract: benefitType?.subcontract,
        segmento: benefitType?.segmento,
        operadora: benefitType?.operadora,
      })
      .pipe(takeUntil(this.destroyed$))
      .subscribe((response) => {
        this.dataSource.data = response;
      })
      .add(() => {
        this.loaderService.hide();
      });
  }

  private loadBenefitTypes(): void {
    this.loaderService.show('Carregando tipos de benefícios...');

    this.beneficiaryService
      .getBenefitTypes(false)
      .pipe(takeUntil(this.destroyed$))
      .subscribe((benefitTypes) => {
        // Esse IF só é feito devido ao fato do Angular reclamar se a variável não for definida, ele não deixa compilar
        if (benefitTypes !== undefined) {
          const primeiro: BenefitType = benefitTypes[0];
          const todos: BenefitType = {
            type: '',
            description: 'Todos',
            flat: '0',
            idClient: primeiro.idClient,
            contract: '0',
            subcontract: '0',
            segmento: '0',
            operadora: '0',
          };
          benefitTypes = [todos, ...benefitTypes];
        }

        this.benefitTypes = benefitTypes;
        this.loadParams();

        if (!this.benefitTypeControl.value) {
          this.benefitTypeControl.setValue(this.benefitTypes[0]);
        }

        this.registerOnBenefitTypeChanges();
        this.loadBeneficiaries();
      })
      .add(() => {
        this.loaderService.hide();
      });
  }

  private registerOnBenefitTypeChanges(): void {
    this.benefitTypeControl.valueChanges.pipe(takeUntil(this.destroyed$)).subscribe((benefitType) => {
      this.router.navigate([], {
        relativeTo: this.activatedRoute,
        queryParams: { benefitType },
      });

      this.loadBeneficiaries();
    });
  }

  private loadParams(): void {
    this.activatedRoute.queryParams.pipe(takeUntil(this.destroyed$)).subscribe(({ idClient }) => {
      if (!idClient) {
        return;
      }

      const benefitType = this.benefitTypes?.find((b) => b.idClient === idClient);

      if (!benefitType) {
        return;
      }

      this.benefitTypeControl.setValue(benefitType);
    });
  }

  private registerSearch(): void {
    // Cria um array de observáveis para os controles de formulário de pesquisa
    const filterObservables = [
      this.pesquisaCtrl.valueChanges,
      this.operadoraCtrl.valueChanges,
      this.segmentoCtrl.valueChanges,
      this.contratoCtrl.valueChanges,
      this.subcontratoCtrl.valueChanges
    ];

    // Combina os observáveis dos controles de formulário para que qualquer mudança em qualquer controle atualize o filtro
    merge(...filterObservables)
      .pipe(takeUntil(this.destroyed$)) // Garante que os observáveis sejam descartados quando o componente for destruído
      .subscribe(() => {
        // Cria um objeto de filtro com os valores dos controles de formulário
        const filterValue = {
          pesquisa: this.pesquisaCtrl.value ?? '',
          operadora: this.operadoraCtrl.value ?? '',
          segmento: this.segmentoCtrl.value ?? '',
          contrato: this.contratoCtrl.value ?? '',
          subcontrato: this.subcontratoCtrl.value ?? ''
        };
        // Converte o objeto de filtro em uma string JSON e define como o filtro da dataSource
        this.dataSource.filter = JSON.stringify(filterValue);
      });

    // Define a função de filtro personalizada para a dataSource
    this.dataSource.filterPredicate = (data: SimpleBeneficiary, filter: string) => {
      // Converte a string JSON de volta para um objeto
      const filterValues = JSON.parse(filter);
      // Verifica se cada campo do objeto de dados corresponde ao valor do filtro correspondente
      return (
        (!filterValues.pesquisa || data.name.toLowerCase().includes(filterValues.pesquisa.toLowerCase())) &&
        (!filterValues.operadora || data.operadora.toLowerCase().includes(filterValues.operadora.toLowerCase())) &&
        (!filterValues.segmento || data.segmento.toLowerCase().includes(filterValues.segmento.toLowerCase())) &&
        (!filterValues.contrato || data.contract.toLowerCase().includes(filterValues.contrato.toLowerCase())) &&
        (!filterValues.subcontrato || data.subcontract.toLowerCase().includes(filterValues.subcontrato.toLowerCase()))
      );
    };
  }
}
