import { HttpClient} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { endpoints } from 'src/environments/endpoints';
import { BeneficiaryMotivos } from '../../models/BeneficiaryMotivos';

@Injectable({ 
  providedIn: 'root'
})
export class MotivosService {
  constructor(private http: HttpClient) {}

  getMotivos(): Observable<BeneficiaryMotivos[]> {
    const baseURL = endpoints.motivos;

    return this.http.get<BeneficiaryMotivos[]>(baseURL);
  }
}