import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, forkJoin } from 'rxjs';
import { map } from 'rxjs/operators';
import { endpoints } from 'src/environments/endpoints';

@Injectable({
  providedIn: 'root',
})
export class UploadService {
  constructor(private http: HttpClient) { }

  uploadFiles(files: File[]): Observable<string[]> {
    const isUndefinedPresent = files.some((item) => item === undefined);
    if (isUndefinedPresent) {
      return of(['']);
    }
    const url = endpoints.uploads;

    const formData = new FormData();
    files.forEach((file) => formData.append('files', file));

    return this.http.post<string[]>(url, formData);
  }

  uploadFilesLogImportacao(files: File[]): Observable<{ originalFile: string; fileUrl: string }[]> {
    const isUndefinedPresent = files.some((item) => item === undefined);
    if (isUndefinedPresent) {
      return of([{ originalFile: '', fileUrl: '' }]);
    }
    const url = endpoints.uploads;

    const uploadObservables = files.map((file, index) => {
      const formData = new FormData();
      formData.append('files', file);

      // Return an observable that emits the response for the uploaded file
      return this.http.post<string>(url, formData).pipe(
        map((fileUrl) => ({
          originalFile: file.name, // Get the original file name
          fileUrl: fileUrl[0] // Assuming the response is the new file URL
        }))
      );
    });

    // Use forkJoin to wait for all uploads to complete and emit the results
    return forkJoin(uploadObservables);
  }
}
