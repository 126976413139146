import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { NotFoundComponent } from './core/components/not-found';
import { AuthGuard } from './core/guards/auth';
import { RoleGuard } from './core/guards/role';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'autenticacao',
  },
  {
    path: '',
    loadChildren: () => import('./modules/auth/auth.module').then((m) => m.AuthModule),
  },
  {
    path: 'inicio',
    loadChildren: () => import('./modules/home/home.module').then((m) => m.HomeModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'pagamentos-e-debitos',
    loadChildren: () =>
      import('./modules/pagamentos-e-debitos/pagamentos-e-debitos.module').then((m) => m.PagamentosEDebitosModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'dados-cadastrais',
    loadChildren: () =>
      import('./modules/dados-cadastrais/dados-cadastrais.module').then((m) => m.DadosCadastraisModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'importacao-beneficiarios',
    loadChildren: () =>
      import('./modules/importacao-beneficiarios/importacao-beneficiarios.module').then(
        (m) => m.ImportacaoBeneficiariosModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'segunda-via',
    loadChildren: () => import('./modules/segunda-via/segunda-via.module').then((m) => m.SegundaViaModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'perfil',
    loadChildren: () => import('./modules/perfil/perfil.module').then((m) => m.PerfilModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'extrato',
    loadChildren: () => import('./modules/extrato/extrato.module').then((m) => m.ExtratoModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'fila-integracao',
    loadChildren: () => import('./modules/fila-integracao/fila-integracao.module').then((m) => m.FilaIntegracaoModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'politicas',
    loadChildren: () => import('./modules/politicas/politicas.module').then((m) => m.PoliticasModule),
  },
  {
    path: 'chamados',
    loadChildren: () => import('./modules/chamados/chamados.module').then((m) => m.ChamadosModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'pagina-nao-encontrada',
    component: NotFoundComponent,
  },
  {
    path: 'perguntas-frequentes',
    loadChildren: () =>
      import('./modules/perguntas-frequentes/perguntas-frequentes.module').then((m) => m.PerguntasFrequentesModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'admin',
    loadChildren: () => import('./modules/admin/admin.module').then((m) => m.AdminModule),
    canActivate: [AuthGuard, RoleGuard],
    data: { roles: ['PJ'] },
  },
  {
    path: '**',
    redirectTo: 'pagina-nao-encontrada',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
