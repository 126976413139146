import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { endpoints } from 'src/environments/endpoints';
import { BeneficiaryDocument } from '../../models/BeneficiaryDocument';

@Injectable()
export class DocumentService {
  constructor(private http: HttpClient) {}

  getDocuments(beneficiaryType: string): Observable<BeneficiaryDocument[]> {
    const baseURL = endpoints.beneficiaries;
    const url = `${baseURL}/documents`;

    const params = {
      beneficiaryType,
    };

    return this.http.get<BeneficiaryDocument[]>(url, { params });
  }

  /**
   * Carrega os documentos a partir das regras do protheus baseando-se nos filtros passados como parâmetros:
   * 
   * @param mode - Ação executada
   * @param beneficiaryType - Tipo de beneficiário: T=Titular ou D=Dependente
   * @param kinship - Grau de parentesco. Ex.: TITULAR, CONJUGE, FILHO(A) MAE, PAI
   * @param contract - Número do contrato
   * @param subContract - Número do subcontrato
  */
  getDocumentsV02(mode: string, beneficiaryType: string, kinship: string, contract: string, subContract: string): Observable<BeneficiaryDocument[]> {
    const baseURL = endpoints.beneficiaries;
    const url = `${baseURL}/get-documents`;

    let actionType = "";
    switch (mode) {
      case "Adicionar":
        actionType = "1";
        break;
      case "Editar":
        actionType = "2";
        break;
      default:
        actionType = "3";
        break;
    }

    const params = {
      actionType,
      beneficiaryType,
      kinship,
      contract,
      subContract
    };

    return this.http.get<BeneficiaryDocument[]>(url, { params });
  }
}
