import { Injectable } from '@angular/core';
import { from, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class IpService {
  constructor() {}

  getUserIp(): Observable<string> {
    return from(this.getAsyncUserIP());
  }

  private async getAsyncUserIP(): Promise<string> {
    try {
      const url = 'https://jsonip.com';
      const response = await fetch(url, { mode: 'cors' });
      const json: { ip: string } = await response.json();
      return json.ip;
    } catch (e) {
      console.log('Failed to get user IP using jsonip.com\n' + e);
      return this.getAsyncUserIP_with_IPIFY()
    }
  }

  private async getAsyncUserIP_with_IPIFY(): Promise<string> {
    try {
      const url = 'https://api.ipify.org?format=json';
      const response = await fetch(url);
      const json: { ip: string } = await response.json();
      return json.ip;
    }
    catch (e) {
      console.log('Failed to get user IP using ipify.org\n' + e);
      throw e;
    }
  }
}
