<app-breadcrumb
  [steps]="[
    ['Início', 'inicio'],
    ['Beneficiários', 'dados-cadastrais/selecionar-beneficiario', { idClient: idClient }],
    [mode + ' ' + 'Beneficiario', '']
  ]"
></app-breadcrumb>
<mat-card class="mt-4">
  <h4 class="mb-4">{{ mode }} {{ type }} {{ beneficiary?.name }}</h4>
  <mat-stepper orientation="vertical" linear *ngIf="showStepper">
    <mat-step [stepControl]="beneficiaryFormGroup">
      <ng-template matStepLabel>Informações de Bloqueio</ng-template>
      <ng-template matStepContent>
        <form [formGroup]="formGroup" (ngSubmit)="onSubmit()">
          <div class="row">
            <div class="col-md-12 col-lg-6">
              <mat-form-field appearance="outline" class="w-100">
                <mat-label>Matrícula</mat-label>
                <input type="text" matInput formControlName="cardNumber" />
                <mat-error *ngIf="formGroup.get('cardNumber')?.hasError('required')">Este campo é obrigatório</mat-error>
              </mat-form-field>
            </div>
            <div class="col-md-12 col-lg-6">
              <mat-form-field appearance="outline" class="w-100">
                <mat-label>Usuário/Emp</mat-label>
                <input type="text" matInput formControlName="name" />
                <mat-error *ngIf="formGroup.get('name')?.hasError('required')">Este campo é obrigatório</mat-error>
              </mat-form-field>
            </div>
            <div class="col-md-12 col-lg-3">
              <mat-form-field appearance="outline" class="w-100">
                <mat-label>Tipo</mat-label>
                <mat-select formControlName="type">
                  <mat-option value="0">Bloquear</mat-option>
                  <mat-option value="1">Desbloquear</mat-option>
                </mat-select>
                <mat-error *ngIf="formGroup.get('lockType')?.hasError('required')">Este campo é obrigatório</mat-error>
              </mat-form-field>
            </div>
            <div class="col-lg-3">
              <mat-form-field appearance="outline" class="w-100">
                <mat-label>Data</mat-label>
                <input type="text" matInput formControlName="blockingDate" mask="00/00/0000" placeholder="dd/mm/yyyy" />
                <mat-error *ngIf="formGroup.get('blockingDate')?.hasError('required')">Este campo é obrigatório</mat-error>
                <mat-error *ngIf="formGroup.get('blockingDate')?.hasError('dateBeforeToday')">
                  Insira uma data posterior ou igual a hoje
                </mat-error>
              </mat-form-field>
            </div>
            <!-- <div class="col-md-12 col-lg-3">
              <mat-form-field appearance="outline" class="w-100">
                <mat-label>Bloquear Fatura</mat-label>
                <mat-select formControlName="blockFat">
                  <mat-option value="0">Sim</mat-option>
                  <mat-option value="1">Não</mat-option>
                </mat-select>
                <mat-error *ngIf="formGroup.get('blockFat')?.hasError('required')">Este campo é obrigatório</mat-error>
              </mat-form-field>
            </div> -->
            <div class="col-md-12 col-lg-6">
              <mat-form-field appearance="outline" class="w-100">
                <mat-label>Motivo</mat-label>
                <mat-select formControlName="reasonCode">
                  <mat-option *ngIf="!formGroup.controls.reasonCode.value; else empty" disabled>Insira o motivo</mat-option>
                  <ng-template #empty>
                    <mat-option disabled *ngIf="!blockReasons?.motivos?.length">Nenhum motivo encontrado</mat-option>
                  </ng-template>
                  <mat-option *ngFor="let blockReason of blockReasons?.motivos" [value]="blockReason.codigobloqueio">
                    {{ blockReason.descricaobloqueio }}
                  </mat-option>
                </mat-select>
                <mat-error *ngIf="formGroup.get('reasonCode')?.hasError('required')">Este campo é obrigatório</mat-error>
              </mat-form-field>
            </div>
            <div class="col-md-12 col-lg-6">
              <mat-form-field appearance="outline" class="w-100">
                <mat-label>Observação</mat-label>
                <input type="text" matInput formControlName="observation" />
              </mat-form-field>
            </div>
            <div class="col-md-12 col-lg-3">
              <mat-form-field appearance="outline" class="w-100">
                <mat-label>Bloquear</mat-label>
                <mat-select formControlName="lockType">
                  <mat-option value="U">Usuário</mat-option>
                  <mat-option value="F">Familia</mat-option>
                </mat-select>
                <mat-error *ngIf="formGroup.get('reasonCode')?.hasError('required')">Este campo é obrigatório</mat-error>
              </mat-form-field>
            </div>
            <div class="col-md-12 col-lg-3">
              <mat-form-field appearance="outline" class="w-100">
                <mat-label>Mat. Sist. Ant</mat-label>
                <input type="text" matInput formControlName="blockInvoice" />
              </mat-form-field>
            </div>
            <!-- <div class="col-lg-3">
              <mat-form-field appearance="outline" class="w-100">
                <mat-label>Data Pedido Bloqueio</mat-label>
                <input
                  type="text"
                  matInput
                  formControlName="requestedBlockingDate"
                  mask="00/00/0000"
                  placeholder="dd/mm/yyyy"
                />
                <mat-error *ngIf="formGroup.get('requestedBlockingDate')?.hasError('dateBeforeToday')">
                  Insira uma data posterior ou igual a hoje
                </mat-error>
              </mat-form-field>
            </div> -->
            <div class="col-lg-6">
              <mat-form-field appearance="outline" class="w-100">
                <mat-label>Data Lançamento Bloqueio</mat-label>
                <input
                  type="text"
                  matInput
                  formControlName="releaseBlockingDate"
                  mask="00/00/0000"
                  placeholder="dd/mm/yyyy"
                />
                <mat-error *ngIf="formGroup.get('releaseBlockingDate')?.hasError('dateBeforeToday')">
                  Insira uma data posterior ou igual a hoje
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col-lg-3">
              <mat-form-field appearance="outline" class="w-100">
                <mat-label>Hora Lançamento Bloqueio</mat-label>
                <input type="text" matInput formControlName="blockingTime" />
              </mat-form-field>
            </div>
            <div class="col-lg-3" *ngIf="canVisualizeException">
              <mat-form-field appearance="outline" class="w-100">
                <mat-label>Exceção</mat-label>
                <mat-select formControlName="excepicion">
                  <mat-option value="N">Não</mat-option>
                  <mat-option value="S">Sim</mat-option>
                </mat-select>
                <mat-error *ngIf="formGroup.get('excepicion')?.hasError('required')">Este campo é obrigatório</mat-error>
              </mat-form-field>
            </div>
            <div class="col-md-12 col-lg-12">
              <mat-form-field appearance="outline" class="w-100">
                <mat-label>Operador</mat-label>
                <input type="text" matInput formControlName="operatorName" />
                <mat-error *ngIf="formGroup.get('operatorName')?.hasError('required')">Este campo é obrigatório</mat-error>
              </mat-form-field>
            </div>
          </div>
          <div class="mt-3">
            <a mat-stroked-button color="secondary" type="button" (click)="goBack()">Voltar</a>
            <button mat-raised-button color="accent" type="submit" [disabled]="cantSave" class="ms-2">Continuar</button>
          </div>
        </form>
      </ng-template>
    </mat-step>
  
    <mat-step [stepControl]="documentsFormGroup">
      <ng-template matStepLabel>Documentos</ng-template>
      <ng-template matStepContent>
        <app-beneficiary-documents
          (saved)="onDocumentsSaved($event)"
          (canceled)="goToStep(0)"
          [mode]="mode"
          [beneficiaryType]="type"
          [beneficiary]="beneficiary"
          [beneficiaryParentId]="null"
          [beneficiaryParent]="null"
        ></app-beneficiary-documents>
      </ng-template>
    </mat-step>
  </mat-stepper>
</mat-card>
