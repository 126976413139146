import { Injectable } from '@angular/core';
import { from, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { HttpErrorResponse } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import Swal, { SweetAlertOptions, SweetAlertResult } from 'sweetalert2';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  private swalConfig: SweetAlertOptions = {
    confirmButtonText: 'Confirmar',
    showCancelButton: true,
    cancelButtonText: 'Cancelar',
    confirmButtonColor: '#7030a0',
    heightAuto: false,
    showCloseButton: true,
    customClass: {
      cancelButton: 'Swal__cancelButton',
    },
  };

  constructor(private toastr: ToastrService) {
    this.toastr.toastrConfig.preventDuplicates = true;
    this.toastr.toastrConfig.resetTimeoutOnDuplicate = true;
    this.toastr.toastrConfig.timeOut = 10000;
    this.toastr.toastrConfig.progressBar = true;
  }

  confirm(options: SweetAlertOptions): Observable<boolean> {
    
    return from(
      // @ts-ignore
      Swal.fire({
        ...this.swalConfig,
        icon: 'question',
        ...options,
      })
    ).pipe(map((result) => result.isConfirmed));
  }

  success(text: string, title = 'Sucesso!'): void {
    this.toastr.success(text, title);
  }

  error(error: string | HttpErrorResponse, title = 'Erro!'): void {
    if (typeof error === 'string') {
      this.toastr.error(error, title);
      return;
    }
  }

  warning(text: string, title = 'Atenção!'): void {
    this.toastr.warning(text, title);
  }

  info(text: string, title = 'Atenção!'): void {
    this.toastr.info(text, title);
  }

  prompt(options: SweetAlertOptions): Observable<SweetAlertResult<any>> {
    return from(
      // @ts-ignore
      Swal.fire({
        ...this.swalConfig,
        input: 'text',
        inputAttributes: {
          autocapitalize: 'off',
        },
        ...options,
      })
    );
  }
}
