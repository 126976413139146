import { environment } from './environment';

const url = environment.url;

export const endpoints = {
  auth: `${url}/auth`,
  users: `${url}/users`,
  client: `${url}/client`,
  beneficiaries: `${url}/beneficiaries`,
  incomeReport: `${url}/incomeReport`,
  enterprise: `${url}/enterprise`,
  invoice: `${url}/invoice`,
  immediateCancellation: `${url}/immediateCancellation`,
  coparticipation: `${url}/coparticipations`,
  dischargeDebtLetter: `${url}/dischargeDebtLetter`,
  kinships: `${url}/kinships`,
  lockTypes: `${url}/lockTypes`,
  uploads: `${url}/uploads`,
  plans: `${url}/plans`,
  dpsQuestions: `${url}/dpsQuestions`,
  cookies: `${url}/cookies`,
  consentForm: `${url}/consent`,
  chamados: `${url}/solicitations`,
  faq: `${url}/faq`,
  banners: `${url}/banners`,
  importacaoMovimentacao: `${url}/importacoesMovimentacao`,
  motivos: `${url}/beneficiaries/list-motivos`,
  filaIntegracao: `${url}/painel-integracoes`,
};
